.events-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.events-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.event-card {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.event-image {
  width: 100%;
  height: auto;
  margin-top: 10px;
}

.event-card h3 {
  margin: 0;
  font-size: 1.5em;
  text-align: center;
  height: 3em; /* Fixed height for title */
  overflow: hidden; /* Hide overflow text */
}

.event-card p {
  color: #666;
  text-align: center;
  height: 2em; /* Fixed height for description */
  overflow: hidden; /* Hide overflow text */
}

.event-card a {
  display: block;
  margin-top: auto; /* Push the link to the bottom */
  color: #1a73e8;
  text-decoration: none;
  text-align: center;
}

.event-card a:hover {
  text-decoration: underline;
}

.loadingEvents {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5em;
  margin-top: 20px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 1100px) {
  .events-list {
    flex-direction: column;
    align-items: center;
  }

  .event-card {
    width: 80%;
  }
}

@media (max-width: 700px) {
  .event-card {
    width: 90%;
  }
}
