.contact-container {
    padding: 20px;
    margin: 20px 35px;
    text-align: center;
    border: 1px solid white;
  }
  
  .contact-title {
    font-size: 1.5rem;
    margin-bottom: 35px;
    color: white;
  }
  
  .contact-address, .contact-phone {
    font-size: 1rem;
    color: #f1f1f1;
    margin: 5px 0;
  }
  
  