.under-construction-container {
  background-color: #fff3e0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: auto;
  text-align: center;
  position: relative;
  top: 100px;
}

.under-construction-title {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #e62319;
}

.under-construction-message {
  font-size: 1.2rem;
  color: #555;
}
