body {
    font-family: "MedievalSharp","Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f0f0f0;
    color: #333;
    overflow-y: scroll;
    overflow-x: hidden;
    font-weight: 600;    
}
