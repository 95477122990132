.carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 500px;
    margin: auto;
}

.carousel-inner {
    display: flex;
    transition: transform 0.5s ease;
}

.carousel-item {
    min-width: 100%;
    box-sizing: border-box;
    position: relative;
}

.image-container {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.main-image {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    z-index: 2;
}

.blurred-background {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    filter: blur(20px);
    -webkit-filter: blur(20px);
    z-index: 1;
    object-fit: cover;
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(53, 45, 45, 0.5);
    color: white;
    border: none;
    padding: 30px 10px;
    cursor: pointer;
    z-index: 3;
}

.carousel-button.left {
    left: 10px;
}

.carousel-button.right {
    right: 10px;
}

.carousel-thumbnails {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.thumbnail {
    width: 60px;
    height: 40px;
    margin: 0 5px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s ease;
}

.thumbnail.active,
.thumbnail:hover {
    opacity: 1;
    border: 2px solid #000;
}
