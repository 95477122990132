body {
    font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin: 0;
    padding: 0;
    overflow: auto;
}

.landing-page {
    color: #ffffff;
    background-color: #000000;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.landing-page.loading {
    background-color: #ffffff;
    overflow: hidden;
}

.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
    animation: scaleLogo 2s 1s forwards, fadeOut 1s 2s forwards;
}

#loading-logo {
    animation: scaleLogo 2s 1s forwards, fadeOut 1s 2s forwards;
    width: 350px;
    /* Set an initial width for the logo */
}

@keyframes scaleLogo {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(35);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.main-content {
    opacity: 0;
    animation: fadeIn 1s forwards;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


.pushdown {
    position: relative;
    top: 87px;
}

.enclosed-content {
    padding: 0px 0px 40px 0px;
    border-radius: 20px;
}

.hero {
    margin: 0;
    text-align: center;
    padding: 0 0;
    width: 100vw;

}

.friends_banner {
    width: 100%;
}

.friends-box {
    align-content: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 1);
    position: relative;
    height: 80px;
    padding: 25px 0;
    height: 100%;
}

.friends-box h2 {
    padding-bottom: .7rem;
}

.hero h1 {
    font-size: 2.5rem;
    color: white;
    margin: 0;
}

.discover {
    text-align: center;
    position: relative;
    border: 1px solid #ffffff;
    margin: 35px;

}

.features {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.feature {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border: 1px solid white;
    margin: 0px 35px 35px 35px;
    position: relative;

}

.feature-text {
    font-weight: 600;
    flex: 1;
    padding: 16px;
    margin: 20px;
    text-align: center;
    align-self: center;
}

.feature h2 {
    color: white;
}

.landing-image {
    max-height: 400px;
    flex: 1;
    align-self: center;
    margin: 15px;
}

.carousel-container h1 {
    text-align: center;

}

.carousel-container {
    border: 1px solid white;
    margin: 0 35px 35px 35px;
    padding: 15px 0px 15px 0px;
}


/* Mobile and Tablet Friendly Styles */

@media (max-width: 1100px) {
    .hero h1 {
        font-size: 2rem;
    }

    .features {
        flex-direction: column;
    }

    .feature {
        flex-direction: column;
        text-align: center;
    }

    .feature-text {
        margin: 0px;
    }

    .enclosed-content {
        padding: 0px 15px 15px 15px;
    }

    .main-content {
        padding: 0 20px 20px 20px;
    }

    #loading-logo {
        width: 350px;
        /* Smaller initial width for the logo on tablet and mobile */
    }

    @keyframes scaleLogo {
        from {
            transform: scale(1);
        }

        to {
            transform: scale(30);
            /* Adjusted scaling for tablet and mobile */
        }
    }
}

@media (max-width: 630px) {
    .hero h1 {
        font-size: 1.5rem;
    }

    .features {
        flex-direction: column;
    }

    .feature {
        flex-direction: column;
        text-align: center;
    }

    .enclosed-content {
        padding: 0px 10px 10px 10px;
    }

    .main-content {
        padding: 0px 10px 10px 10px;
    }

    .landing-image {
        width: 80%;
    }

    #loading-logo {
        width: 350px;
        /* Smaller initial width for the logo on mobile */
    }

    @keyframes scaleLogo {
        from {
            transform: scale(1);
        }

        to {
            transform: scale(17);
            /* Adjusted scaling for mobile */
        }
    }
}

.button-89 {
    text-decoration: none;
    --b: 3px;
    /* border thickness */
    --s: .45em;
    /* size of the corner */
    --color: #f6f7fb;

    padding: calc(.5em + var(--s)) calc(1.9em + var(--s));
    color: var(--color);
    --_p: var(--s);
    background:
        conic-gradient(from 90deg at var(--b) var(--b), #0000 90deg, var(--color) 0) var(--_p) var(--_p)/calc(100% - var(--b) - 2*var(--_p)) calc(100% - var(--b) - 2*var(--_p));
    transition: .3s linear, color 0s, background-color 0s;
    outline: var(--b) solid #0000;
    outline-offset: .6em;
    font-size: 1.3rem;
    position: relative;
    top: 0.25rem;

    border: 0;

    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.button-89:hover,
.button-89:focus-visible {
    --_p: 0px;
    outline-color: var(--color);
    outline-offset: .05em;
}

.button-89:active {
    background: var(--color);
    color: #fff;
}