.team-section {
    text-align: center;
    padding: 20px;
}

.team-section h2 {
    font-size: 2em;
    margin-bottom: 10px;
}

.team-section .quote {
    font-style: italic;
    margin-bottom: 30px;
}

.team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.team-member {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 45%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.photo-info {
    display: flex;
    align-items: center;
}

.member-image {
    border-radius: 25px;
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-right: 20px;
}

.info {
    flex: 1;
}

.team-member h3 {
    font-size: 1.2em;
    margin: 10px 0;
}

.team-member .title {
    font-size: 0.9em;
    color: #888;
    margin-bottom: 10px;
}

.team-member .description {
    font-size: 0.9em;
    margin-bottom: 10px;
}

.social-icons {
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
}

.social-icon {
    text-decoration: none;
    font-size: 1.2em;
    color: #333;
    padding: 5px;
}

.social-icon:hover {
    color: #0073e6;
    
}


/* Media Queries for Tablet View */
@media (max-width: 1100px) {
    .team-member {
        width: 100%;
    }
    .member-name {
        text-align: center;
    }
    .title  {
        text-align: center;
    }
    .photo-info {
        flex-direction: column;
        align-self: center;
    }

    .member-image {
        margin-right: 0;
        margin-bottom: 10px;
    }

}


/* Media Queries for Mobile View */
@media (max-width: 700px) {
    .team-member {
        width: 100%;
    }

    .member-name {
        text-align: center;
    }
    .title  {
        text-align: center;
    }

    .photo-info {
        flex-direction: column;
    }

    .member-image {
        margin-right: 0;
        margin-bottom: 10px;
    }

}
