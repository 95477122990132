.specials-table-section {
  text-align: center;
  padding: 20px;
}

.specials-table-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.specials-table-section .animated-hr {
  width: 0;
  height: 3px;
  background-color: #444;
  border: none;
  margin: 20px auto;
  animation: grow 1.5s forwards;
}

@keyframes grow {
  from {
    width: 0;
  }
  to {
    width: 80%;
  }
}

.specials-table {
  width: 80%;
  margin: 0 auto;
  border-collapse: collapse;
  opacity: 0;
  animation: fadeIn 1.5s forwards;
  animation-delay: 1.5s; /* Delay the table animation until the hr animation finishes */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.specials-table th,
.specials-table td {
  border: 1px solid #ddd;
  padding: 1.6rem;
}

.specials-table th {
  background-color: #272727; /* Slightly darker background */
  color: #fff; /* White text color */
  font-weight: bold; /* Bold text */
  font-size: 1.1em; /* Slightly larger text */
}

.specials-table tr:nth-child(even) {
  background-color: #e2dfdf;
}

.current-day {
  background-color: rgba(0, 147, 81, 1); /* Subtle green background */
  font-weight: bold;
  color: #fff; /* White text color */
  animation: pulse 3s infinite; /* Slower pulsing animation */
}

@keyframes pulse {
  0% {
    background-color: rgba(0, 147, 81, 1);
    transform: scale(1);
  }
  50% {
    background-color: rgb(13, 99, 60); /* Slightly darker green */
    transform: scale(1.01);
  }
  100% {
    background-color: rgba(0, 147, 81, 1);
    transform: scale(1);
  }
}

@keyframes growmobile {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Media Queries for Mobile View */
@media (max-width: 750px) {
  .specials-table {
    width: 100%;
    font-size: 0.9em; /* Smaller font size */
  }

  .specials-table th,
  .specials-table td {
    padding: 1.6rem 1rem; /* Smaller padding */
  }

  .specials-table-section h2 {
    font-size: 1.5em; /* Smaller heading size */
  }

  .specials-table-section .animated-hr {
    width: 0;
    height: 3px;
    background-color: #444;
    border: none;
    margin: 10px auto;
    animation: growmobile 1.5s forwards;
  }
}

