.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #000000;
  z-index: 100;
  width: 100%;
  flex-wrap: wrap;
}

.logo img {
  height: 50px;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  right: 60px;
}

.nav a {
  font-weight: 500;
  font-size: 1.5rem;
  color: #ffffff;
  text-decoration: none;
  margin-left: 1rem;
  transition: color 0.3s ease-in-out;
}

.nav a:hover {
  color: #00aced;
}

.pushdown-other {
  position: relative;
  top: 87px;
}

.fb {
  position: relative;
  bottom: 4px;
}

.nav-toggle {
  display: none;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 2rem;
  position: relative;
  right: 60px;
}

@media (max-width: 750px) {
  .nav {
      display: none;
      flex-direction: column;
      text-align: center;
      background-color: #000; /* Added background color for better visibility */
      padding: 1rem;
      z-index: 99;
      position: absolute;
      top: 60px;
      left: 0;
  }

  .nav.nav-visible {
      display: flex;
  }

  .nav a {
      margin: 1rem 0;
  }

  .nav-toggle {
      display: block;
      right: 60px; /* Keeps the button in its original position */
  }

  .nav-toggle :hover {
    color: #00aced;
  }
  
}